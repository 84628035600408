var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('PageWrapDefault',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.makingApiRequest),expression:"makingApiRequest"}],attrs:{"errorMessage":_vm.errorMessage,"successMessage":_vm.successMessage,"topActionButtonPrimary":{
    text: 'Save',
    key: 'saveNewCompany',
    disabled: _vm.disablePrimaryButton,
    loading: _vm.makingApiRequest
  },"topActionButtonSecondary":{
    text: 'Cancel',
    key: 'cancelAddingNewCompany',
    loading: _vm.isLoading
  },"element-loading-text":_vm.loadingText},on:{"toolbarSelectItem":_vm.toolbarSelectItem,"errorMessageClosed":function($event){_vm.errorMessage = ''},"successMessageClosed":function($event){_vm.successMessage = ''}}},[_c('div',{staticClass:"mb-8 md:mb-12 flex justify-between"},[_c('div',[_c('h3',{staticClass:"text-lg leading-6 font-medium text-gray-900"},[_vm._v(" Company Details ")]),_c('p',{staticClass:"mt-1 text-sm text-gray-500"},[_vm._v(" Provide some information about the company. ")])])]),_c('form-builder',{ref:"formElement",attrs:{"schemas":_vm.companyFields,"formValues":_vm.editing,"dynamicOptions":{},"buttons":[]},on:{"formFieldChanged":_vm.formFieldChangeHandler,"validationChanged":_vm.validationChangeHandler}}),_c('CompanyEndorsements',{attrs:{"endorsements":_vm.companyEndorsements},on:{"changed":_vm.updateEndorsements}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }