import Vue from "vue";
import FormBuilderDataCollector from "@/components/FormBuilder/FormBuilderDataCollector.vue";
import {
  companiesMapMutations,
  companiesMapState
} from "@/store/modules/companies";
import * as types from "@/store/mutation-types";
export interface ICompanyData {
  disablePrimaryButton: boolean;
  companyEndorsements: any[];
  errorMessage: string;
  isLoading: boolean;
  isAdmin: boolean;
  successMessage: string;
  loadingText: string;
  resourceNotFound: boolean;
}

export default Vue.extend({
  components: {
    FormBuilderDataCollector
  },
  data(): ICompanyData {
    return {
      disablePrimaryButton: true,
      companyEndorsements: [],
      errorMessage: "",
      isLoading: false,
      isAdmin: true,
      successMessage: "",
      loadingText: "",
      resourceNotFound: false
    };
  },

  methods: {
    ...companiesMapMutations({ editField: types.SET_EDIT_FIELD }),
    doEditField(key: any, value: any): void {
      this.editField({
        key,
        value
      });
    }
  },
  computed: {
    ...companiesMapState(["editing", "makingApiRequest"])
  }
});
