
import { getSchemaKeys } from "@/components/FormBuilder/Helpers/utils/getSchemaKeys";
import { FormBlock } from "@/components/FormBuilder/types";
import { set } from "lodash";
import * as dotObj from "dot-object";
import Vue from "vue";

export default Vue.extend({
  name: "form-builder-data-collector",
  props: {
    show: {
      type: Boolean,
      default: false,
      required: false
    },
    schemas: {
      type: Array as Vue.PropType<FormBlock[]>,
      required: false,
      default: () => []
    },
    description: {
      type: String,
      required: false,
      default: ""
    },
    title: {
      type: String,
      required: false,
      default: "Form Filler"
    },
    editing: {
      type: Object,
      required: false,
      default: () => null
    },
    valueMainKey: {
      type: String,
      default: "applicant"
    },
    emitOnChange: {
      type: Boolean,
      default: false,
      required: false
    },
    allowDelete: {
      type: Boolean,
      default: false,
      required: false
    },
    dynamicOptions: {
      default() {
        return {};
      },
      type: Object
    },
    persistAfterSubmit: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  created() {},
  data() {
    return {
      loading: false,
      formValues: getSchemaKeys(this.schemas),
      validationData: {}
    };
  },
  methods: {
    submitHandler(): void {
      const action = this.editing ? "edit" : "add";
      this.$emit(action, this.formValues);
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 1250);
      if (!this.persistAfterSubmit) this.resetFormData();
    },
    deleteHandler(): void {
      this.$emit("delete", this.formValues);
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 1250);
    },
    formFieldChangedHandler(payload: { key: string; value: any }) {
      if (payload.key === "applicant.ssn") {
        if (typeof payload.value === "string") {
          set(this.formValues, payload.key, payload.value);
        }
        return;
      }
      const { key, value } = payload;
      set(this.formValues, key, value);
      if (this.emitOnChange) {
        this.submitHandler();
      }
    },
    handleValidationChanged($event: Record<string, any>) {
      this.validationData = $event;
      this.$emit("formValidationChanged", $event);
    },
    resetFormData() {
      this.formValues = getSchemaKeys(this.schemas);
      this.validationData = {};
    }
  },
  computed: {
    isVisible: {
      get(): boolean {
        return this.show;
      },
      set(): void {
        this.resetFormData();
        this.$emit("close");
      }
    },
    saveButtonTitle(): string {
      if (this.editing) {
        return "Save";
      } else return "Add";
    },
    deleteButtonTitle(): string {
      return "Delete";
    }
  },
  watch: {
    editing: {
      immediate: true,
      handler(value: any) {
        if (value) {
          this.$set(this, "formValues", {
            ...this.formValues,
            ...dotObj.dot({ [this.valueMainKey]: value })
          });
        }
      }
    }
  }
});
