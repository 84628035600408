<template>
  <div v-if="endorsements.length">
    <div class="font-medium text-base">
      The following endorsements will apply for this company
    </div>
    <div class="flex justify-between">
      <div>
        <div class="mr-60">
          <div class="text-gray-500 font-semibold mt-2">
            Mandatory Endorsements:
          </div>
          <ol style="padding-left: 0; text-align: left;" class="my-2">
            <li
              ref=""
              v-for="(endorsement, index) in mandatoryEndorsements"
              :key="index"
              class="my-2"
            >
              <el-tooltip
                class="cursor-pointer"
                effect="dark"
                content="Remove endorsement"
                placement="left"
              >
                <span
                  @click="removeMandatoryEndorsement(endorsement.formNumber)"
                  ><i class="el-icon-remove text-red-700 mr-2"></i
                ></span>
              </el-tooltip>
              {{ endorsement.formNumber }} {{ endorsement.formName }}
            </li>
          </ol>
        </div>
        <div v-if="deSelectedMandatoryEndorsements.length">
          <div class="text-gray-500 font-semibold mt-10" :key="forceUpdateKey">
            Deselected Mandatory Endorsements:
          </div>
          <ol
            style="padding-left: 0; list-style:disc;text-align: left;"
            class="my-2 max-h-72 overflow-auto"
          >
            <li
              ref=""
              v-for="(endorsement, index) in deSelectedMandatoryEndorsements"
              :key="index"
              class="my-2"
            >
              <el-tooltip
                class="cursor-pointer"
                effect="dark"
                content="Add endorsement"
                placement="left"
              >
                <span @click="addMandatoryEndorsement(endorsement.formNumber)"
                  ><i class="el-icon-circle-plus text-green-700 mr-2"></i
                ></span>
              </el-tooltip>
              {{ endorsement.formNumber }}
              {{ endorsement.formName }}
            </li>
          </ol>
        </div>
      </div>
      <div>
        <div class="text-gray-500 font-semibold mt-2" :key="forceUpdateKey">
          Optional Endorsements:
        </div>
        <ol
          style="padding-left: 0; list-style:disc;text-align: left;"
          class="my-2 max-h-72 overflow-auto"
        >
          <li
            ref=""
            v-for="(endorsement, index) in optionalEndorsements"
            :key="index"
            class="my-2"
          >
            <el-tooltip
              class="cursor-pointer"
              effect="dark"
              content="Remove endorsement"
              placement="left"
            >
              <span @click="removeOptionalEndorsement(endorsement.formNumber)"
                ><i class="el-icon-remove text-red-700 mr-2"></i
              ></span>
            </el-tooltip>
            {{ endorsement.formNumber }}
            {{ endorsement.formName }}
          </li>
        </ol>

        <div v-if="deSelectedOptionalEndorsements.length">
          <div class="text-gray-500 font-semibold mt-10" :key="forceUpdateKey">
            Deselected Optional Endorsements:
          </div>
          <ol
            style="padding-left: 0; list-style:disc;text-align: left;"
            class="my-2 max-h-72 overflow-auto"
          >
            <li
              ref=""
              v-for="(endorsement, index) in deSelectedOptionalEndorsements"
              :key="index"
              class="my-2"
            >
              <el-tooltip
                class="cursor-pointer"
                effect="dark"
                content="Add endorsement"
                placement="left"
              >
                <span @click="addOptionalEndorsement(endorsement.formNumber)"
                  ><i class="el-icon-circle-plus text-green-700 mr-2"></i
                ></span>
              </el-tooltip>
              {{ endorsement.formNumber }}
              {{ endorsement.formName }}
            </li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as types from "@/store/mutation-types";
import {
  companiesMapMutations,
  companiesMapState
} from "@/store/modules/companies";
import { remove } from "lodash";

export default {
  name: "companyEndorsements",
  props: {
    endorsements: {
      type: Array,
      required: true,
      default: () => []
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      forceUpdateKey: 0,
      deSelectedOptionalEndorsements: [],
      deSelectedMandatoryEndorsements: []
    };
  },
  methods: {
    ...companiesMapMutations({ editField: types.SET_EDIT_FIELD }),
    removeOptionalEndorsement(formNumber) {
      const toAdd = this.optionalEndorsements.find(endorsement => {
        return endorsement.formNumber === formNumber;
      });
      this.deSelectedOptionalEndorsements.push(toAdd);
      remove(this.optionalEndorsements, function(element) {
        return element.formNumber === formNumber;
      });
      this.$emit("changed", [
        ...this.mandatoryEndorsements,
        ...this.optionalEndorsements
      ]);
      this.forceUpdateKey++;
    },
    removeMandatoryEndorsement(formNumber) {
      const toAdd = this.mandatoryEndorsements.find(endorsement => {
        return endorsement.formNumber === formNumber;
      });
      this.deSelectedMandatoryEndorsements.push(toAdd);
      remove(this.mandatoryEndorsements, function(element) {
        return element.formNumber === formNumber;
      });
      this.$emit("changed", [
        ...this.optionalEndorsements,
        ...this.mandatoryEndorsements
      ]);
      this.forceUpdateKey++;
    },
    addOptionalEndorsement(formNumber) {
      const toAdd = this.deSelectedOptionalEndorsements.find(endorsement => {
        return endorsement.formNumber === formNumber;
      });
      this.optionalEndorsements.push(toAdd);
      remove(this.deSelectedOptionalEndorsements, function(element) {
        return element.formNumber === formNumber;
      });

      this.forceUpdateKey++;
    },
    addMandatoryEndorsement(formNumber) {
      const toAdd = this.deSelectedMandatoryEndorsements.find(endorsement => {
        return endorsement.formNumber === formNumber;
      });
      this.mandatoryEndorsements.push(toAdd);
      remove(this.deSelectedMandatoryEndorsements, function(element) {
        return element.formNumber === formNumber;
      });

      this.forceUpdateKey++;
    }
  },
  computed: {
    ...companiesMapState(["editing"]),
    mandatoryEndorsements() {
      return this.endorsements.filter(
        endorsements => endorsements.formUsage === "Mandatory"
      );
    },
    optionalEndorsements: {
      get: function() {
        return this.endorsements.filter(
          endorsements => endorsements.formUsage === "Optional"
        );
      },
      set: function() {}
    }
  }
};
</script>

<style></style>
