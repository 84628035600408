
import { validateFields } from "@/helpers";
import {
  companiesMapActions,
  companiesMapMutations
} from "@/store/modules/companies";
import { getCompanyDefaults } from "@/helpers/companies";
import { objectDifference } from "@/helpers";
import { companyForm } from "@/forms/admin/company";
import { FormBlock } from "@/components/FormBuilder/types";
import { ICompany } from "@/store/modules/companies/types";
import * as selectOptions from "@/helpers/selectOptions";
import CompanyEndorsements from "@/components/companies/CompanyEndorsements.vue";
import { endorsementMapActions } from "@/store/modules/endorsements";
import CompanyMixin from "./CompanyMixin";
import VueWithMixins from "@/helpers/mixins";
export default VueWithMixins(CompanyMixin).extend({
  name: "createCompany",
  components: { CompanyEndorsements },
  created() {
    this.SET_EDIT({} as any);
  },
  destroyed() {
    this.SET_EDIT(" " as any);
  },
  computed: {
    updatedFields(): any {
      const edited = this.editing;
      const original = getCompanyDefaults({});
      return edited && original ? objectDifference(edited, original) : {};
    },
    companyFields(): FormBlock[] {
      return companyForm(
        this.companyOptions,
        this.lineOfBusinessOptions,
        "create"
      );
    },
    companyOptions(): { label: string; value: string }[] {
      const initData = this.$store.state.auth["initData"];
      const { companies = [] } = initData;
      return companies.map((company: ICompany) => ({
        label: company.companyName,
        value: company.companyNumber
      }));
    },
    lineOfBusinessOptions(): any[] {
      if ((this.editing as ICompany).structuredAfter === 27) {
        return selectOptions.companyLineOfBusiness.slice(0, 2);
      } else {
        return selectOptions.companyLineOfBusiness;
      }
    }
  },
  methods: {
    ...companiesMapMutations(["SET_EDIT", "SET_COMPANY_FORM_TYPES"]),
    ...companiesMapActions(["createCompanies"]),
    ...endorsementMapActions(["getEndorsements"]),
    updateEndorsements(value: any) {
      (this.companyEndorsements as any[]) = value;
    },
    async toolbarSelectItem(event: string) {
      switch (event) {
        case "saveNewCompany":
          await this.submit();
          break;
        case "cancelAddingNewCompany":
          this.$router.push("/admin/companies").catch(() => {});
          break;
      }
    },
    async submit(): Promise<void> {
      try {
        this.loadingText = "Creating company. Please wait...";
        const refs = this.$refs;
        const isValid = validateFields(refs);
        if (isValid) {
          this.isLoading = true;
          const endorsements = this.companyEndorsements.map(endorsement => {
            return {
              formCode: endorsement.formCode,
              formNumber: endorsement.formNumber
            };
          });
          const payload = { ...this.editing, endorsements };
          await this.createCompanies(payload as any);
          this.$router.replace("/admin/companies");
          this.SET_EDIT({} as any);
        } else {
          throw new Error(
            "Form invalid. Please check the fields for missing values."
          );
        }
      } catch (err) {
        this.errorMessage = err.message;
        this.$bugSnagClient.notify(err);
      } finally {
        this.isLoading = false;
        this.loadingText = "";
      }
    },
    validationChangeHandler(e: any) {
      const { formIsValid } = e;
      this.disablePrimaryButton = !formIsValid;
    },
    async formFieldChangeHandler({ key, value }: { key: any; value: any }) {
      if (key === "structuredAfter" && value) {
        const number4Companies = [18, 23, 24, 26, 28, 30];
        const fieldsToFill = ["companyLineOfBusiness", "site", "formTypes"];
        const initData = this.$store.state.auth["initData"];
        const { companies = [] } = initData;
        const matchedCompany = companies.find((company: ICompany) => {
          return company.companyNumber === value;
        });

        this.doEditField("mCompany", number4Companies.includes(value) ? 4 : 1);

        for (let field of fieldsToFill) {
          if (field === "formTypes") {
            for (let formType of matchedCompany.formTypes) {
              formType.value = formType.formTypes;
              this.SET_COMPANY_FORM_TYPES(formType);
            }
          }
          this.doEditField(field as keyof ICompany, matchedCompany[field]);
        }
        this.companyEndorsements = await this.getEndorsements({
          companies__in: [value]
        });
      }
      if (key === "formTypes") {
        this.SET_COMPANY_FORM_TYPES(value);
      } else {
        this.doEditField(key, value);
      }
    }
  }
});
