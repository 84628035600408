import Vue from "vue";
const { __getText } = Vue.prototype;
import { FormBlock } from "@/components/FormBuilder/types";
import {
  useFormSection,
  useFormFieldGroup,
  useTextField,
  useSelectField,
  useField
} from "@/components/FormBuilder/Helpers";
import * as selectOptions from "@/helpers/selectOptions";
import { getCompaniesPrefixes } from "@/helpers/companies";
import store from "@/store";
import { isValidPastEffectiveDate } from "@/helpers/validateEffectiveDate";
const companyPrefixes = getCompaniesPrefixes("companiesPage");

type ICompanyPageType = "create" | "edit";

function getLineOfBusinessFormTypes(lineOfBusiness: string, options: any) {
  const editingCompany = store.getters["companies/editingCompany"];
  if (editingCompany && editingCompany.formTypes) {
    let result: any = [];
    const companyFormTypes = editingCompany.formTypes.find(
      (formType: any) => formType.lineOfBusiness === lineOfBusiness
    );
    if (!companyFormTypes) return options;

    const { formTypes: selectFormTypes } = companyFormTypes;
    if (selectFormTypes.length > 0) {
      const currentFormTypes = selectFormTypes.map((form: any) => {
        return {
          label: form.formName,
          value: form.value
        };
      });
      result = [...currentFormTypes];

      const currentFormTypesValues = currentFormTypes.map(
        (form: any) => form.value
      );

      options.forEach((element: any) => {
        if (!currentFormTypesValues.includes(element.value)) {
          result.push(element);
        }
      });
      return result;
    }
  }
  return options;
}

export function companyForm(
  companyOptions: any[],
  lineOfBusinessOptions?: any[],
  pageType?: ICompanyPageType | undefined
): FormBlock[] {
  const base = [
    ...useFormSection([
      ...useFormFieldGroup(
        [
          useTextField({
            key: "companyName",
            label: __getText("companies", "companyName"),
            placeholder: __getText("companies", "companyName"),
            required: true
          })
        ],
        { layout: "2-col" }
      ),
      ...useFormFieldGroup(
        [
          useTextField({
            key: "companyNumber",
            label: __getText("companies", "companyNumber"),
            placeholder: __getText("companies", "companyNumber"),
            required: true,
            validations: [
              {
                method: "integer",
                error: __getText("fields", "numberError")
              }
            ]
          }),
          useTextField({
            key: "NAICCD",
            label: __getText("companies", "NAICCD"),
            placeholder: __getText("companies", "NAICCD"),
            required: true,
            validations: [
              {
                method: "integer",
                error: __getText("fields", "numberError")
              }
            ]
          })
        ],
        { layout: "2-col" }
      ),
      ...useFormFieldGroup(
        [
          useSelectField({
            key: "companyPrefix",
            label: "Company Prefixes",
            options: companyPrefixes,
            allowCreate: true,
            allowFirstOption: true,
            filterable: true,
            required: true,
            multiple: true
          }),
          useSelectField({
            key: "site",
            label: __getText("companies", "site"),
            options: selectOptions.siteOptions,
            required: true
          })
        ],
        { layout: "2-col" }
      ),
      ...useFormFieldGroup(
        [
          useSelectField({
            key: "companyLineOfBusiness",
            label: __getText("companies", "companyLineOfBusiness"),
            options: lineOfBusinessOptions,
            required: true,
            multiple: true
          }),
          useField({
            key: "formTypes",
            type: "custom-form-types-field",
            label: "Homeowner Form Types",
            options: getLineOfBusinessFormTypes(
              "H",
              selectOptions.homeownerFormTypes
            ),
            lineOfBusiness: "H",
            allowCreate: true,
            allowFirstOption: true,
            filterable: true,
            required: false,
            multiple: true
          })
        ],
        { layout: "2-col" }
      ),
      ...useFormFieldGroup(
        [
          useField({
            key: "formTypes",
            type: "custom-form-types-field",
            label: "Dwelling Fire Form Types",
            options: getLineOfBusinessFormTypes(
              "D",
              selectOptions.dwellingFireFormTypes
            ),
            lineOfBusiness: "D",
            allowCreate: true,
            allowFirstOption: true,
            filterable: true,
            required: false,
            multiple: true
          }),
          useField({
            key: "formTypes",
            type: "custom-form-types-field",
            label: "Tenant/Condo Form Types",
            options: getLineOfBusinessFormTypes(
              "T",
              selectOptions.tenantCondoFormTypes
            ),
            lineOfBusiness: "T",
            allowCreate: true,
            allowFirstOption: true,
            filterable: true,
            required: false,
            multiple: true
          })
        ],
        { layout: "2-col" }
      )
    ])
  ];

  const structuredAfterField: any = [
    ...useFormSection(structuredAfterBase(companyOptions), {
      title: __getText("companies", "structureCompany")
    })
  ];

  if (pageType === "create") {
    base.unshift(...structuredAfterField);
  }

  return base;
}

function structuredAfterBase(companyOptions: any[]) {
  return [
    ...useFormFieldGroup([
      useField({
        key: "structuredAfter",
        type: "select",
        label: "Which existing company should we structure this company after?",
        placeholder: __getText("companies", "structureCompany"),
        options: companyOptions,
        required: false,
        clearable: true
      })
    ])
  ];
}
export const quoteCountyValidationsForm = (disabled = false): FormBlock[] => {
  return [
    ...useFormSection([
      ...useFormFieldGroup([
        useField(
          {
            key: "isActive",
            type: "checkbox",
            label: "Enable Validation",
            required: false
          },
          ["mb-6"]
        )
      ]),
      ...useFormFieldGroup(
        [
          useField({
            key: "title",
            label: "Validation Title",
            required: true,
            type: "text",
            disabled: disabled
          }),
          useField({
            key: "effectiveDate",
            label: "Date Validation takes effect",
            required: true,
            type: "date",
            disabled: disabled,
            pickerOptions: {
              disabledDate: (fieldValue: Date) =>
                !isValidPastEffectiveDate(fieldValue, 1)
            }
          }),
          useField({
            key: "county",
            label: "County",
            type: "select-search-gbs-helps",
            gbsSearchType: "county",
            placeholder: "County",
            required: false,
            disabled: disabled,
            filterable: true,
            useLabelAsValue: true,
            options: [],
            clearable: true,
            allowCreate: true
          })
        ],
        { layout: "3-col" }
      ),
      ...useFormFieldGroup(
        [
          useField({
            key: "city",
            label: "City",
            type: "select-search-gbs-helps",
            placeholder: "City",
            gbsSearchType: "city",
            disabled: disabled,
            useLabelAsValue: true,
            required: false,
            clearable: true
          }),
          useSelectField({
            key: "state",
            label: "State",
            required: false,
            disabled: disabled,
            options: selectOptions.states,
            filterable: true,
            clearable: true
          }),
          useField({
            key: "zipCode",
            label: "Zip Code",
            placeholder: "Zip Code",
            disabled: disabled,
            multiple: true,
            required: false,
            type: "select-search-gbs-helps",
            gbsSearchType: "zip",
            useLabelAsValue: true,
            clearable: true,
            allowCreate: true
          })
        ],
        { layout: "3-col" }
      ),
      ...useFormFieldGroup(
        [
          useField({
            key: "companyNumbers",
            label: "Company Numbers",
            disabled: disabled,
            multiple: true,
            required: true,
            type: "select"
          }),
          useField({
            key: "exemptedRoles",
            label: "Exempted Roles",
            disabled: disabled,
            required: false,
            multiple: true,
            options: selectOptions.userRoles,
            type: "select",
            information: {
              title: "Exempted Roles",
              body: `Exempted roles specifies the roles that this validation won't affect`
            }
          }),
          useSelectField({
            key: "windCheck",
            label: "Wind",
            required: true,
            disabled: disabled,
            options: selectOptions.windCheck,
            filterable: true,
            clearable: false,
            information: {
              title: "Info",
              body: `Quotes that qualify for option selected here will be skipped`
            }
          })
        ],
        { layout: "3-col" }
      ),
      ...useFormFieldGroup(
        [
          useField({
            key: "exemptedAgencyCodes",
            label: "Exempted Agency Codes",
            disabled: disabled,
            multiple: true,
            required: false,
            type: "select-search-agencies",
            showOnlyAgencyCodes: true,
            useAgencyCodeAsValue: true,
            conditions: {
              or: [
                {
                  operator: "includes",
                  field: "exemptedRoles",
                  value: "agent"
                },
                {
                  operator: "includes",
                  field: "exemptedRoles",
                  value: "subAdmin"
                },
                {
                  operator: "includes",
                  field: "exemptedRoles",
                  value: "agencyAdmin"
                }
              ]
            }
          }),
          useField({
            key: "validationMessage",
            label: "Validation message",
            disabled: disabled,
            required: true,
            type: "textarea"
          })
        ],
        { layout: "2-col" }
      )
    ])
  ];
};
