import { IUserModel } from "@/types";
import { addDays, isAfter, isBefore, subDays } from "date-fns";
import { isExemptedUserRole } from ".";
import { stripAgencyCode } from "./generalHelpers";
import { get } from "lodash";
import isToday from "date-fns/isToday";

export function isValidPastEffectiveDate(date: string | Date, days = 90) {
  let dateObj: Date;
  if (date instanceof Date) {
    dateObj = date;
  } else {
    dateObj = new Date(date);
  }
  return isAfter(dateObj, subDays(new Date(), days));
}

export function isValidFutureEffectiveDate(date: string | Date) {
  let dateObj: Date;
  if (date instanceof Date) {
    dateObj = date;
  } else {
    dateObj = new Date(date);
  }
  return isBefore(dateObj, addDays(new Date(), 120));
}

export function isValidEffectiveDate(
  date: string | Date,
  createdOn: string | Date,
  user?: IUserModel
) {
  const today = isToday(new Date(createdOn));
  const agentCode = stripAgencyCode(
    user?.agentCode ? user?.agentCode : ""
  ).toLowerCase();
  const agentCodeFrmk = agentCode === "frmk";

  return agentCodeFrmk
    ? isValidPastEffectiveDate(date, 4) && isValidFutureEffectiveDate(date)
    : isExemptedUserRole(get(user, "role", ""))
    ? true
    : isValidPastEffectiveDate(date, today ? 1 : 3) &&
      isValidFutureEffectiveDate(date);
}
